import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import {
  getParkWebBannerText,
  isBannerEnabled,
  sendBannerClickEvent,
} from "../../banner/Banner";
import { Settings } from "../../config";
import { QueryParam } from "../../commons/Constants";
import GoDaddyLogo from "../../banner/GDLogo";
import { useLanderConfig } from "../../commons/ConfigContext";
import { AdContent } from "../AdContent";
import { LinkButton } from "../commonComponents/LinkButton";
import LayoutStyle from "./LayoutStyle";
import classes from "./ParkWebLayout.module.scss";

export default function ParkWebLayout({
  landerConfig,
  queryConfig,
  page,
  content,
}) {
  let domainContent = "";

  if (isBannerEnabled(landerConfig)) {
    domainContent = (
      <div>
        <Domain landerConfig={landerConfig} />
        <DomainInfo landerConfig={landerConfig} content={content} />
        <GetDomainButton landerConfig={landerConfig} />
      </div>
    );
  }

  let adContent = null;
  const adsense = (
    <AdContent
      landerConfig={landerConfig}
      queryConfig={queryConfig}
      page={page}
    />
  );
  if (
    !queryConfig[QueryParam.DISABLE_ADTILE] &&
    landerConfig.adTile &&
    landerConfig.adTile.searchResultCode === "SUCCESSFUL"
  ) {
    adContent = (
      <div className="adRow">
        {adsense}
        <AdTileBlock landerConfig={landerConfig} />
      </div>
    );
  } else {
    adContent = <div className="center">{adsense}</div>;
  }

  return (
    <div id={"contentLayout"}>
      <LayoutStyle template={landerConfig.lander.template} />
      <div id={"domainBanner"}>
        <GoDaddyLogo width={192} height={40} />
        {domainContent}
      </div>
      {adContent}
      <CopyrightAndPrivacy
        copyrightTextId="parkwebcopyrightText"
        landerConfig={landerConfig}
      />
    </div>
  );
}

/**
 * @return {null || html}
 */
export function GetDomainButton({ landerConfig }) {
  const link = ((landerConfig.lander || {}).banner || {}).link;
  if (typeof link === "undefined" || link === "") {
    return null;
  }
  const type = ((landerConfig.lander || {}).banner || {}).type;
  const getDomainClick = function (bannerType) {
    sendBannerClickEvent(bannerType, landerConfig);
  };
  return (
    <div id={"getButton"}>
      <div id={"getButtonBox"}>
        <a
          id={"getButtonBoxLink"}
          href={link}
          onClick={() => getDomainClick(type)}
        >
          <FormattedMessage id="GetThisDomainCTA" />
        </a>
      </div>
    </div>
  );
}

export function AdTileRow({ adTileDomain }) {
  let salePrice = adTileDomain.fullPrice;
  let crossedPrice;
  if (adTileDomain.discountPrice) {
    crossedPrice = adTileDomain.fullPrice;
    salePrice = adTileDomain.discountPrice;
  }

  const sendReporting = useCallback(() => {
    fetch(
      `${Settings.PARKWEB_LANDER_API}/v1/parking/domainsFind/feedback/${adTileDomain.domainGuid}`,
      {
        method: "POST",
      }
    );
  }, [adTileDomain.domainGuid]);

  return (
    <div className="adTileRow">
      <div className="adTileDomainLabel">
        {adTileDomain.isAd && (
          <span className="adTilePromoted">
            <FormattedMessage id="AT-Promoted" />
          </span>
        )}
        <div className="adTileDomain">{adTileDomain.fqdn}</div>
      </div>
      <div className="adTilePrices">
        {crossedPrice && (
          <span className="crossedPrice">
            <FormattedNumber
              value={crossedPrice}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="USD"
            />
          </span>
        )}
        <span className="salePrice">
          {/* eslint-disable-next-line react/style-prop-object */}
          <FormattedNumber value={salePrice} style="currency" currency="USD" />
        </span>
        <LinkButton link={adTileDomain.link} onClick={sendReporting}>
          <FormattedMessage id="AT-Buy" />
        </LinkButton>
      </div>
    </div>
  );
}

function AdTileBlock() {
  const config = useLanderConfig();
  return (
    <div id="adTile">
      <h1>
        <FormattedMessage id="AT-LookingForSimilarDomains" />
      </h1>
      <p>
        <FormattedMessage id="AT-CheckOutTheseTopPicks" />
      </p>
      <div className="verticalTable">
        {config.landerConfig.adTile.domains.map((domain, idx) => (
          <AdTileRow key={idx} adTileDomain={domain} />
        ))}
      </div>
    </div>
  );
}

/**
 * @return {null || html}
 */
export function DomainInfo({ landerConfig, content }) {
  const text = getParkWebBannerText(landerConfig, content);
  if (typeof text === "undefined" || text === "") {
    return null;
  }
  return <div id={"domainInfo"}>{text}</div>;
}

/**
 * @return {null || html}
 */
export function Domain({ landerConfig }) {
  const domain = (landerConfig.domain || {}).rootDomainDisplayText;
  if (typeof domain === "undefined" || domain === "") {
    return null;
  }
  return <div id={"domain"}>{domain}</div>;
}

/**
 * @return {html}
 */

export function CopyrightAndPrivacy({
  copyrightTextId,
  landerConfig,
  parkwebDisclaimerText = null,
}) {
  const privacyUrl =
    (landerConfig.turnKeyReseller || {}).privacyUrl ||
    Settings.PRIVACY_POLICY_URL;

  function getModalPrivacy() {
    window.open(
      privacyUrl,
      "privacy",
      "width=520,height=400,left=250,top=250,menubar=no,status=yes,toolbar=no,scrollbars=yes,resizable=yes"
    );
  }
  return (
    <>
      <div id={"copyright"}>
        <FormattedMessage
          id={copyrightTextId}
          values={{ currentYear: new Date().getFullYear() }}
        />{" "}
        <span id="privacy">
          <a href="#!" rel="nofollow" onClick={getModalPrivacy}>
            <FormattedMessage id="privacyPolicyText" />
          </a>
        </span>
      </div>
      {parkwebDisclaimerText && (
        <p className={classes.parkwebDisclaimerText}>
          <FormattedMessage id={parkwebDisclaimerText} />
        </p>
      )}
    </>
  );
}

CopyrightAndPrivacy.propTypes = {
  copyrightTextId: PropTypes.string,
  landerConfig: PropTypes.object,
  parkwebDisclaimerText: PropTypes.string,
};

// queryConfig is a required parameter in getDefaultRelatedLinkOptions
ParkWebLayout.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};

GetDomainButton.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};

DomainInfo.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  content: PropTypes.object,
};

Domain.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};

AdTileBlock.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};
AdTileRow.propTypes = {
  adTileDomain: PropTypes.object.isRequired,
};
